import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { ProviderLogin } from "./auth/login/ProviderLogin";
import { FamilyLogin } from "./auth/login/FamilyLogin";
import { ForgotPassword } from "./auth/ForgotPassword";
import { USER_TYPES } from "../constant";
import { ResetPassword } from "./auth/ResetPassword";
import { FamilyRegistration } from "./auth/register/FamilyRegistration";
import { Providers } from "./providers/Providers";
import { QrMobileImageUpload } from "./qr-image-upload/QrMobileImageUpload";
import { Tools } from "./provider/Tools";
import { Onboarding } from "./provider/onboarding/OnboardingOverview"
import { ProviderChat } from "./provider/ProviderChat";
import { FamilyChat } from "./family/chat/FamilyChat";
import { MailBoxProvider } from "./mailbox/context/mailbox-context";
import { Library } from "./provider/Library/Library";
import { FileProvider } from "./provider/Library/context/file-context";
import { BulletinList } from "./provider/bulletin/BulletinList";
import { RequestRBT } from "./provider/request-rbt/RequestRBT";
import { RequestAuthList } from "./provider/request-auth/RequestAuthList";
import { ClientReferral } from "./auth/refer/ClientReferral";
import { SurveyModal } from "./survey/SurveyModal";

const RoutesList = () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route path="login" element={<FamilyLogin />} />
          <Route
            path="forgot-password"
            element={<ForgotPassword userType={USER_TYPES.FAMILY} />}
          />
          <Route path="qr-image-upload" element={<QrMobileImageUpload />} />
          <Route
            path="reset-password"
            element={<ResetPassword userType={USER_TYPES.FAMILY} />}
          />
          <Route path="register" element={<FamilyRegistration />} />
          <Route path="register/:partialId" element={<FamilyRegistration />} />
          <Route path="refer-client" element={<ClientReferral />} />
          <Route path="providers" element={<Providers />} />
          <Route path="aba-therapy-providers" element={<Providers version="aba-therapy-providers" />} />
          <Route path="autism-therapy-providers" element={<Providers version="autism-therapy-providers" />} />
          <Route path="providers/:providerFirst/:providerLast" element={<Providers />} />
        </Route>
        <Route path="/provider">
          <Route path="login" element={<ProviderLogin />} />
          <Route
            path="forgot-password"
            element={<ForgotPassword userType={USER_TYPES.PROVIDER} />}
          />
          <Route
            path="reset-password"
            element={<ResetPassword userType={USER_TYPES.PROVIDER} />}
          />
        </Route>
        <Route element={<Main />}>
          <Route
            path="/family/dashboard"
            element={
              <SuspenseWrapper>
                <MailBoxProvider>
                  <FamilyChat />
                </MailBoxProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/onboarding"
            element={
              <SuspenseWrapper>
                <Onboarding />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/onboarding/:screen"
            element={
              <SuspenseWrapper>
                <Onboarding />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/dashboard"
            element={
              <SuspenseWrapper>
                <Tools />
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/chat"
            element={
              <SuspenseWrapper>
                <MailBoxProvider>
                  <ProviderChat />
                </MailBoxProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/library"
            element={
              <SuspenseWrapper>
                <FileProvider>
                  <Library />
                </FileProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/request-rbt"
            element={
              <SuspenseWrapper>
                <FileProvider>
                  <RequestRBT />
                </FileProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/request-auth"
            element={
              <SuspenseWrapper>
                <FileProvider>
                  <RequestAuthList />
                </FileProvider>
              </SuspenseWrapper>
            }
          />
          <Route
            path="/provider/bulletin"
            element={
              <SuspenseWrapper>
                <BulletinList />
              </SuspenseWrapper>
            }
          />
          <Route
            index
            element={
              // if loggged in redirect to dashboard
              <Navigate to="/login" />
            }
          />
        </Route>
      </Routes>
      <SurveyModal />
    </>

  );
};
export default RoutesList;
