import { GoogleForm, GoogleFormProvider, useGoogleForm, useShortAnswerInput } from 'react-google-forms-hooks'
import { PopupModal } from "../../components/PopupModal";
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { useUser } from "../auth/context/user-context";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading/Loading";
import { api } from "../../services/api";
import CheckboxInput from './components/CheckboxInput';
import RadioInput from './components/RadioInput';
import ShortAnswerInput from './components/ShortAnswerInput';
import LongAnswerInput from './components/LongAnswerInput';
import RadioGridInput from './components/RadioGridInput';
import CheckboxGridInput from './components/CheckboxGridInput';
import DropdownInput from './components/DropdownInput';
import LinearGrid from './components/LinearGrid';
import { Button } from '../../components/Button';


export type CustomFormDto = {
  title: string;
  description?: string;
  url: string;
  formJson: GoogleForm;
}

export function SurveyModal() {
  const { userInfo, refreshUserInfo } = useUser();
  const [onLoadCounter, setOnLoadCounter] = useState(0);
  // const methods = useGoogleForm({ form: userInfo?.assignedForms?.[0].formJson });
  // const [submit, setSubmit] = useState(false);

  const onSuveryMarkedCompleted = async () => {
    await api.markFormCompleted(userInfo.assignedForms[0].id);
    await refreshUserInfo();
    setOnLoadCounter(0);
  }

  const loading = false


  // if (!userInfo || !userInfo.assignedForms || userInfo.assignedForms.length === 0) return null;

  const form = userInfo?.assignedForms?.[0] as CustomFormDto;


  const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0;
`

  const QuestionContainer = styled.div`
  margin-bottom: 20px;
`

  const QuestionLabel = styled.h3`
  margin-bottom: 10px;
`

  const QuestionHelp = styled.p`
  font-size: 0.8rem;
  color: #5c5c5c;
  margin-top: 0px;
`


  // const Questions = () => {
  //   return (
  //     <div>
  //       {form.formJson.fields.map((field) => {
  //         const { id } = field

  //         let questionInput
  //         switch (field.type) {
  //           case 'CHECKBOX':
  //             questionInput = <CheckboxInput id={id} />
  //             break
  //           case 'RADIO':
  //             questionInput = <RadioInput id={id} />
  //             break
  //           case 'SHORT_ANSWER':
  //             questionInput = <ShortAnswerInput id={id} />
  //             break
  //           case 'LONG_ANSWER':
  //             questionInput = <LongAnswerInput id={id} />
  //             break
  //           case 'RADIO_GRID':
  //             questionInput = <RadioGridInput id={id} />
  //             break
  //           case 'CHECKBOX_GRID':
  //             questionInput = <CheckboxGridInput id={id} />
  //             break
  //           case 'DROPDOWN':
  //             questionInput = <DropdownInput id={id} />
  //             break
  //           case 'LINEAR':
  //             questionInput = <LinearGrid id={id} />
  //             break
  //         }

  //         if (!questionInput) {
  //           return null
  //         }

  //         return (
  //           <QuestionContainer key={id}>
  //             <QuestionLabel>{field.label}</QuestionLabel>
  //             {questionInput}
  //             <QuestionHelp>{field.description}</QuestionHelp>
  //           </QuestionContainer>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  // const onSubmit = async (data) => {
  //   setSubmit(true)
  //   console.log('>>> Here is the data', data)
  //   await methods.submitToGoogleForms(data)
  //   alert('Form submitted with success!')
  // }


  return (
    userInfo?.assignedForms?.[0] ?
      <PopupModal
        contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
        shouldStopPropagation={false}
      >
        {
          loading ? <Loading /> :
            <>
              <div className="w-full justify-between items-start flex">
                <div className="inline-block text-black text-l sm:text-2xl font-bold font-['Outfit'] leading-7">
                  Survey: {form.title}
                </div>

              </div>
              <div className="w-full max-h-[calc(100vh-250px)] lg:max-h-[calc(100vh-250px)] overflow-auto" id="provider-scroll">
                {/* <iframe title="google-form-embedded" src="https://docs.google.com/forms/d/e/1FAIpQLSePjfpETqixOOYSeJRDLmFocNBHt6gz1NjqCHl5-ClWBU1fZA/viewform?embedded=true" width='100%' height={650} frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe> */}
                {/* <GoogleFormProvider {...methods}>
                  <Form onSubmit={() => { methods.handleSubmit(onSubmit) }}
                    action={form.url.replace('/viewform','/formResponse')}
                    target="hidden_iframe"
                  >
                    {form.title && (
                      <>
                        <h1>{form.title}</h1>
                        {form.description && (
                          <p style={{ fontSize: '.8rem' }}>{form.description}</p>
                        )}
                      </>
                    )}
                    <Questions />
                    <button type='submit'>Submit</button>
                  </Form>
                  <iframe title="google-form" style={{ display: "none" }} name="hidden_iframe" id="hidden_iframe" onLoad={() => submit ? {} : ""}></iframe>
                </GoogleFormProvider> */}
                <iframe onLoad={() => setOnLoadCounter(onLoadCounter + 1)} src={form.url.includes('?') ? `${form.url}&embedded=true` : `${form.url}?embedded=true`} title={`Survey: ${form.title}`} className="w-full h-[calc(100vh-310px)] lg:h-[calc(100vh-310px)]" />

                {onLoadCounter > 1 && <div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="!rounded-full w-full mt-2"
                    // disabled={disabled || saving}
                    loading={loading}
                    onClick={onSuveryMarkedCompleted}
                  >
                    Suvey Completed
                  </Button>
                </div>}
              </div>
            </>
        }
      </PopupModal> : null);

}
